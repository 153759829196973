<template>
	<div class="default_page">
		<a-layout class="layout-default" id="layout-default" :class="[layoutClass]">
			<a-layout-content>
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive" :key="key" />
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive" :key="key" />
			</a-layout-content>
		</a-layout>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {}
		},
		computed: {
			layoutClass() {
				return this.$route.meta.layoutClass
			},
			key() {
				return this.$route.path
			}
		}
	}
</script>

<style lang="scss" scoped>
	.default_page {
		height: 100vh;
		background: #f0f2f5;
		overflow: hidden;
		display: flex;
		// align-items: center;
	}
</style>
