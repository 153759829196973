// 会话
const video = {
    videos: {
        url: "/addons/chatgpt/video/videos",
        method: "GET",
        desc: "AI视频"
    },
    prompts: {
        url: "/addons/chatgpt/video/prompts",
        method: "GET",
        desc: "AI视频模板"
    },
    prompts_config: {
        url: "/addons/chatgpt/video/prompts_config",
        method: "GET",
        desc: "AI视频模板配置"
    },
    generate: {
        url: "/addons/chatgpt/video/generate",
        method: "POST",
        desc: "生成视频"
    },
    getJob: {
        url: "/addons/chatgpt/video/getJob",
        method: "GET",
        desc: "点击按钮  修复视频"
    },
    getUpscaleJob: {
        url: "/addons/chatgpt/video/getUpscaleJob",
        method: "GET",
        desc: "获取7.Upscale结果"
    },
    video_result: {
        url: "/addons/chatgpt/video/video_result",
        method: "GET",
        desc: "视频生成结果"
    },
    user_video_msg: {
        url: "/addons/chatgpt/video/user_video_msg",
        method: "GET",
        desc: "AI视频任务"
    },
    user_video: {
        url: "/addons/chatgpt/video/user_video",
        method: "GET",
        desc: "AI视频记录"
    },
    msg_video_del: {
        url: "/addons/chatgpt/video/msg_video_del",
        method: "GET",
        desc: "删除AI视频记录"
    },
    video_del: {
        url: "/addons/chatgpt/video/video_del",
        method: "GET",
        desc: "删除AI视频"
    },
    video_vote: {
        url: "/addons/chatgpt/video/video_vote",
        method: "GET",
        desc: "视频点赞收藏"
    },
    video_vote_list: {
        url: "/addons/chatgpt/video/video_vote_list",
        method: "GET",
        desc: "视频收藏点赞记录"
    },
    translate: {
        url: "/addons/chatgpt/video/translate",
        method: "GET",
        desc: "视频 描述 翻译"
    },
    template: {
        url: "/addons/chatgpt/video/template",
        method: "GET",
        desc: " AI视频创作模板"
    },

    















}

export default video
