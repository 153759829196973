<template>
	<div>
		<!-- Main Sidebar -->
		<a-layout-sider collapsible class="scroll-display-none layout_aside" :class="{ hide: sidebarCollapsed }"
			breakpoint="lg" collapsed-width="164px" width="164px" :collapsed="sidebarCollapsed"
			@collapse="$emit('toggleSidebar', !sidebarCollapsed)" :trigger="null" theme="light">
			<div class="aside_box">
				<div class="aside_logo">
					<img :src="config.logo ? config.logo : require('@/assets/imgs/logo.png')" alt="" />
					<span>{{ config.name || "" }}</span>
				</div>
				<div class="aside_menu">
					<a-menu mode="inline" :selectedKeys="[selectedKey]" @select="handleMenuSelect">
						<a-menu-item :key="1">
							<router-link to="/ai" :data-key="1">
								<img v-if="selectedKey == 1" src="@/assets/icons/menu1_s.png" alt="" />
								<img v-else src="@/assets/icons/menu1_d.png" alt="" />
								<span>会话</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="2">
							<router-link to="/write" :data-key="2">
								<img v-if="selectedKey == 2" src="@/assets/icons/menu2_s.png" alt="" />
								<img v-else src="@/assets/icons/menu2_d.png" alt="" />
								<span>工具</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="3">
							<router-link to="/role" :data-key="3">
								<img v-if="selectedKey == 3" src="@/assets/icons/menu3_s.png" alt="" />
								<img v-else src="@/assets/icons/menu3_d.png" alt="" />
								<span>角色</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="4">
							<!-- <router-link to="/drawing" :data-key="4">
								<img v-if="selectedKey == 4" src="@/assets/icons/menu4_s.png" alt="" />
								<img v-else src="@/assets/icons/menu4_d.png" alt="" />
								<span>绘画</span>
							</router-link> -->
							<div class="lefthover" @click="todraw">
								<img src="@/assets/icons/menu4_d.png" alt="" />
								<span>创作</span>
							</div>
						</a-menu-item>
						<!-- <a-menu-item :key="5">
							<div class="lefthover" @click="tosquare">
								<img src="@/assets/icons/menu6_d.png" alt="" />
								<span>社区资源</span>
							</div>
						</a-menu-item> -->
						<a-menu-item :key="5">
							<router-link to="/more" :data-key="5">
								<img v-if="selectedKey == 5" src="@/assets/icons/menu5_s.png" alt="" />
								<img v-else src="@/assets/icons/menu5_d.png" alt="" />
								<span>工具箱</span>
							</router-link>
						</a-menu-item>
					</a-menu>
				</div>
				<div class="aside_foot">
					<!-- <a-menu mode="inline" @select="handleMenuSelect">
						<a-menu-item :key="6">
							<router-link to="/dashboard" :data-key="6">
								<img src="@/assets/icons/side1.png" alt="" />
								<span>仪表盘</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="7" v-if="token">
							<router-link to="/profile" :data-key="7">
								<img src="@/assets/icons/side2.png" alt="" />
								<span>用户信息</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="8" v-if="token">
							<router-link to="/recharge" :data-key="8">
								<img src="@/assets/icons/side3.png" alt="" />
								<span>充值中心</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="9" v-if="token && config.draw_switch == 1">
							<router-link to="/photo" :data-key="9">
								<img src="@/assets/icons/side4.png" alt="" />
								<span>作品集</span>
							</router-link>
						</a-menu-item>
						<a-menu-item :key="10" v-if="token">
							<a href="javascript:void(0);" @click="showModal = true">
								<img src="@/assets/icons/side5.png" alt="" />
								<span>联系我们</span>
							</a>
						</a-menu-item>
						<a-menu-item :key="11" v-if="token">
							<a href="javascript:void(0);" @click="logoutDialog = true">
								<img src="@/assets/icons/side6.png" alt="" />
								<span>退出</span>
							</a>
						</a-menu-item>
					</a-menu> -->
					<div class="version">系统版本：{{ config.version }}</div>
				</div>
			</div>
		</a-layout-sider>
		<!-- / Main Sidebar -->
		<a-modal v-if="config.contact_us && config.contact_us.content" v-model="showModal" :width="400" :footer="null"
			:centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="show_modal">
				<div class="contact" v-html="config.contact_us.content"></div>
			</div>
		</a-modal>

		<a-modal v-model="logoutDialog" title="提示" @ok="logoutOK" cancel-text="取消">
			<p>确认要退出登录吗？</p>
		</a-modal>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
	props: {
		// 遮罩层状态
		sidebarCollapsed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			showModal: false,
			selectedKey: 0,
			logoutDialog: false
		}
	},
	computed: {
		...mapGetters("user", ["token"]),
		...mapGetters("app", ["config"])
	},
	watch: {
		$route: {
			handler(to, from) {
				if (to.path === "/ai" || to.path == "/") {
					this.selectedKey = 1
				}
			},
			deep: true
		}
	},
	mounted() {
		this.initSelectKey()
	},
	methods: {
		// 初始化选中菜单项
		initSelectKey() {
			const defKey = document.querySelector(".ant-menu-item a.router-link-active").getAttribute("data-key")
			this.selectedKey = defKey
		},
		// 切换菜单项
		handleMenuSelect(e) {
			this.selectedKey = e.key
		},
		// 退出登录
		logoutOK() {
			this.$store.dispatch("user/logout")
			this.logoutDialog = false
		},
		todraw() {
			localStorage.setItem("squareTab", 1)

			let url = `${window.location.origin}/#/square?type=1`;
			window.open(url, '_blank');
		},
		tosquare() {
			let url = `${window.location.origin}/#/square?type=1`;
			window.open(url, '_blank');
		}
		// logoutHandle() {
		// 	this.$confirm({
		// 		title: "确认要退出登录吗？",
		// 		content: "",
		// 		okText: "确认",
		// 		cancelText: "取消",
		// 		onOk: () => {
		// 			return new Promise((resolve, reject) => {
		// 				this.$store.dispatch("user/logout")
		// 				resolve()
		// 			}).catch(() => {})
		// 		}
		// 	})
		// }
	}
}
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-content {
	border-radius: 10px;
	overflow: hidden;
}

.show_modal {
	.contact {
		::v-deep img {
			width: 100%;
			height: auto;
		}
	}
}

.layout_aside {
	width: 164px;
	height: 100%;
	position: fixed;
	left: 0;
	z-index: 10;
	background: transparent;
	transform: translateX(0);
	transition: all 0.3s;

	&.hide {
		transform: translateX(-100%);
	}

	.aside_box {
		width: 100%;
		height: 100%;
		background: #e7f2fa;
		border: 1px solid #fff;
		display: flex;
		flex-direction: column;

		.aside_logo {
			padding: 20px;
			text-align: center;
			font-size: 16px;
			color: #0c1d40;

			img {
				width: 30px;
				height: 30px;
			}
		}

		.ant-menu-inline {
			border: none;

			.ant-menu-item {
				margin: 0;
				overflow: visible;

				&::after {
					display: none;
				}
			}
		}

		.ant-menu-item {
			padding: 0 !important;
			height: auto;
			line-height: normal;

			&.ant-menu-item:active,
			&.ant-menu-item-selected {
				background-color: transparent;
			}

			a {
				padding: 20px;
				border-radius: 8px;
			}

			&:hover {
				a {
					background: rgba($theme_color, 0.05);
				}
			}

			img {
				width: 20px;
				height: 20px;
				margin-right: 8px;
				transform: translateY(-2px);
			}

			span {
				font-size: 14px;
				color: #677ba5;
			}

			.router-link-active {
				background: $card_color;
				border-radius: 10px;

				&:hover {
					background: $card_color;
				}

				span {
					color: #0052d9;
				}
			}
		}

		.aside_menu {
			padding: 20px;
			flex: 1;
		}

		.aside_foot {
			background: linear-gradient(180deg, #f3f8fc, rgba(243, 248, 252, 0));
			border-radius: 10px 10px 0px 0px;

			.ant-menu-item {
				a {
					padding: 20px 40px;
				}
			}

			.version {
				font-size: 12px;
				color: #677ba5;
				text-align: center;
				padding: 20px;
			}
		}
	}
}


.lefthover {
	width: 123px;
	height: 61px;
	display: flex;
	align-items: center;
	// justify-content: center;
	padding-left: 20px;
	cursor: pointer;
}

.lefthover:hover {
	background: rgb(219, 234, 248);
	border-radius: 10px;
}
</style>
