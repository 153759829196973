import { http } from "@/api/api"
import router from "@/router"

export default {
	namespaced: true,
	state: {
		token: "",
		userInfo: "",
		loginPopup: false,
		statisticInfo: ""
	},
	getters: {
		token: state => state.token,
		userInfo: state => state.userInfo,
		loginPopup: state => state.loginPopup,
		statisticInfo: state => state.statisticInfo
	},
	mutations: {
		setToken(state, token) {
			state.token = token
		},
		removeToken(state) {
			state.token = ""
		},
		userInfo(state, data) {
			state.userInfo = data
		},
		removeUserInfo(state) {
			state.userInfo = ""
		},
		setLoginPopup(state, value) {
			state.loginPopup = value
		},
		statisticInfo(state, data) {
			state.statisticInfo = data
		}
	},
	actions: {
		// 退出登录
		async logout({ commit }) {
			await new Promise(resolve => {
				http("login.logout")
				resolve()
			})
			commit("removeToken")
			commit("removeUserInfo")
			location.reload()
			// return
			// if (router.currentRoute.path.indexOf("/ai") != -1) {
			// 	location.reload()
			// } else {
			// 	router.replace("/").then(() => {
			// 		location.reload()
			// 	})
			// }
		},
		// 获取用户信息
		async getUserInfo({ commit, dispatch, getters, state }, token = "") {
			const result = await new Promise((resolve, reject) => {
				token && commit("setToken", token)
				http("user.info")
					.then(res => {
						if (res.code === 1) {
							commit("userInfo", res.data)
							resolve(res)
						} else {
							reject(res)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
			// token && router.go(0)
			return result
		},
		// 获取统计数据
		async getStatisticInfo({ commit, dispatch, getters, state }) {
			const result = await new Promise((resolve, reject) => {
				http("common.statistic")
					.then(res => {
						if (res.code === 1) {
							commit("statisticInfo", res.data)
							resolve(res)
						} else {
							reject(res)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
			return result
		}
	}
}
