<template>
	<component class="layout_header" :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top">
		<a-layout-header style="padding: 0 10px">
			<a-row type="flex">
				<a-col :span="6" :md="6" class="header_left_info">
					<a-button type="link" class="sidebar-toggler" v-if="sidebarCollapsed"
						@click="$emit('toggleSidebar', !sidebarCollapsed), resizeEventHandler()">
						<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path
								d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
						</svg>
					</a-button>

					<a-breadcrumb v-if="$route.matched.length" style="">
						<a-breadcrumb-item>
							<router-link to="/">
								<svg style="transform: translateY(2px)" width="16" height="16"
									xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
									<path
										d="M1011.017143 560.091429L556.8 24.685714a57.6 57.6 0 0 0-89.6 0L12.982857 560.091429a57.6 57.6 0 0 0 44.8 93.805714c31.817143 0 77.348571 25.782857 77.348572 57.6v251.611428a57.6 57.6 0 0 0 57.6 57.6h102.948571a57.6 57.6 0 0 0 57.6-57.6v-171.154285a57.6 57.6 0 0 1 57.6-57.6h202.24a57.6 57.6 0 0 1 57.6 57.6v171.154285a57.6 57.6 0 0 0 57.6 57.6h102.948571a57.6 57.6 0 0 0 57.6-57.6V711.497143c0-31.817143 45.531429-57.6 77.348572-57.6a57.6 57.6 0 0 0 44.8-93.805714z">
									</path>
								</svg>
							</router-link>
						</a-breadcrumb-item>
						<a-breadcrumb-item v-for="(item, index) in breadcrumb($route.matched)" :key="'route_' + index">
							<router-link :to="item.path" v-if="$route.meta.title && item.path != $route.path">{{
		item.meta.title }}</router-link>
							<span v-if="item.name && item.path == $route.path">{{ item.meta.title }}</span>
						</a-breadcrumb-item>
					</a-breadcrumb>
				</a-col>

				<a-col :span="18" :md="18" class="header_right_info">

					<div class="rightbox" v-if="userInfo">
						<img class="head_item_right_img" src="@assets/imgs/jifen_icon.png" alt="" />
						{{ userInfo.usable || 0 }}
					</div>
					<div class="user_info" v-if="userInfo">
						<el-dropdown @command="command">
							<span class="el-dropdown-link">
								<a-avatar :src="userInfo.avatar" />
								<span class="nickname">{{ userInfo.nickname }}</span>

							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="1">用户信息</el-dropdown-item>
								<el-dropdown-item command="2">数据看板</el-dropdown-item>
								<el-dropdown-item command="3">充值中心</el-dropdown-item>
								<el-dropdown-item command="4">联系我们</el-dropdown-item>
								<el-dropdown-item command="5">关注公众号</el-dropdown-item>
								<el-dropdown-item command="6">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="user_unlogin" v-else @click="setLoginPopup(true)">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
								fill="#111827" />
						</svg>
						<span class="text">点击登录</span>
					</div>
					<!-- <div class="user_setting" @click="$emit('toggleSettingsDrawer', true)">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
								fill="#111827"
							/>
						</svg>
					</div> -->
				</a-col>
			</a-row>
		</a-layout-header>

		<LoginCard></LoginCard>

		<a-modal v-if="config.contact_us && config.contact_us.content" v-model="showModal" :width="400" :footer="null"
			:centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="show_modal">
				<div class="contact" v-html="config.contact_us.content"></div>
			</div>
		</a-modal>

		<a-modal v-model="logoutDialog" title="提示" @ok="logoutOK" cancel-text="取消">
			<p>确认要退出登录吗？</p>
		</a-modal>

		<a-modal v-if="showModelType === 'wxoa' &&
		config.wxOfficialAccount &&
		config.wxOfficialAccount.qrcode
		" v-model="code_showModal" :width="400" :footer="null" :centered="true" :closable="false" :maskClosable="true"
			:bodyStyle="{ padding: 0 }">
			<div class="code_show_modal">
				<div class="wxoa">
					<img :src="config.wxOfficialAccount.qrcode" alt="" />
				</div>
			</div>
		</a-modal>
	</component>
</template>

<script>
import LoginCard from "@/components/Custom/LoginCard.vue"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
	components: { LoginCard },
	props: {
		// 固定头部
		navbarFixed: {
			type: Boolean,
			default: false
		},
		// 遮罩层状态
		sidebarCollapsed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			// 固定头部的位置
			top: 0,
			// 挂载到哪个容器
			wrapper: document.body,
			showModal: false,
			logoutDialog: false,
			code_showModal: false,
			showModelType: 'wxoa'
		}
	},
	computed: {
		...mapGetters("user", ["userInfo", "token"]),
		...mapGetters("app", ["config"])

	},
	watch: {},
	created() {
		window.addEventListener("resize", this.resizeEventHandler)
	},
	mounted() {
		this.wrapper = document.getElementById("layout-dashboard")
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler)
	},
	methods: {
		...mapMutations("user", ["setLoginPopup"]),
		breadcrumb(data) {
			return data && data.filter(item => item.name && item.meta.title != "首页")
		},
		resizeEventHandler() {
			this.top = this.top ? 0 : -0.01
		},
		command(e) {
			console.log(e)
			if (e == 1) {
				this.$router.push("/dashboard")
			} else if (e == 2) {
				this.$router.push("/profile")
			} else if (e == 3) {
				this.$router.push("/recharge")
			} else if (e == 4) {
				this.showModal = true
			} else if (e == 5) {
				this.code_showModal = true
			} else if (e == 6) {
				this.logoutDialog = true
			}
		},
		// 退出登录
		logoutOK() {
			this.$store.dispatch("user/logout")
			this.logoutDialog = false
		},
	}
}
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-header {
	border: none;
}

.layout_header {
	color: #677ba5;

	svg {
		path {
			fill: #677ba5;
		}
	}

	.ant-btn-link {
		height: auto;
		padding: 0 8px;
		margin: 0;
		box-shadow: none;
		color: #677ba5;
	}

	.ant-breadcrumb a {
		&:hover {
			color: $theme_color;
		}
	}

	.header_left_info {
		display: flex;
		align-items: center;
	}

	.header_right_info {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.rightbox {
			display: flex;
			align-items: center;
			padding: 0 20px;
		}

		.user_info {
			.nickname {
				margin: 0 10px;
				font-weight: 600;
				// color: #0c1d40;
			}

			cursor: pointer;
		}

		.user_unlogin {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 0 10px;

			.text {
				margin-left: 4px;
			}
		}

		.user_setting {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}
}

.show_modal {
	.contact {
		::v-deep img {
			width: 100%;
			height: auto;
		}
	}
}

.code_show_modal {
	.wxoa {
		img {
			width: 400px;
			height: 400px;
		}
	}

	.wxscan {
		width: 400px;
		height: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contact {
		::v-deep img {
			width: 100%;
			height: auto;
		}
	}
}
</style>
